import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { iOS } from '../../../Utilities';
import './LazyLoadCustom.scss';

const LazyLoadCustom = (props) => {
  const { src, alt, className, style, onClick, customeffect, onLoad } = props;
  return !iOS() ? (
    <LazyLoadImage
      onClick={onClick}
      src={src}
      afterLoad={onLoad}
      placeholder={<div style={{ height: '100%', width: '100%', background: '#eee' }}></div>}
      alt={alt}
      className={className}
      style={{ ...style }}
      loading='lazy'
      effect={'opacity'}
    />
  ) : src ? (
    <img
      onLoad={onLoad}
      src={src}
      onClick={onClick}
      alt={alt}
      className={className}
      style={style}
      loading='lazy'
    />
  ) : null;
};

export default LazyLoadCustom;
