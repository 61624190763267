import { dashboardConstants } from '../../constants';

const initialState = {
  dashboardData: {},
  locationData: {},
  creditsAvailable: 501,
  redirectPath: null,
  scrolledHeight: 0,
  firebaseToken: '',
  subscribedTopicsArray: [],
  navbardata: null,
  restrictionModal: false,
};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.DASHBOARDDATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    case dashboardConstants.LOCATIONDATA:
      return {
        ...state,
        locationData: action.payload,
      };

    case dashboardConstants.CREDITS:
      return {
        ...state,
        creditsAvailable: action.payload,
      };

    case dashboardConstants.RESTRICT:
      return {
        ...state,
        restrictionModal: action.payload,
      };

    case dashboardConstants.REDIRECTPATH:
      return {
        ...state,
        redirectPath: action.payload,
      };

    case dashboardConstants.SCROLLEDHEIGHT:
      return {
        ...state,
        scrolledHeight: action.payload,
      };

    case dashboardConstants.FIREBASETOKEN:
      return {
        ...state,
        firebaseToken: action.payload,
      };

    case dashboardConstants.TOPICSARRAY:
      return {
        ...state,
        subscribedTopicsArray: action.payload,
      };

    case dashboardConstants.CLEARDASHBOARDDATA:
      return {
        ...state,
        dashboardData: {},
      };
    case dashboardConstants.NAVBARDATA:
      return {
        ...state,
        navbardata: action.payload,
      };
    default:
      return state;
  }
}

export const getCurrentDashboardData = (state) => state.dashboard.dashboardData;
export const getCurrentLocationData = (state) => state.dashboard.locationData;
export const getCurrentRedirectPath = (state) => state.dashboard.redirectPath;
export const getCurrentSccrolledHeight = (state) => state.dashboard.scrolledHeight;
export const getFirebaseToken = (state) => state.dashboard.firebaseToken;
export const getSubscribedTopicsArray = (state) => state.dashboard.subscribedTopicsArray;
export const getNavbarData = (state) => state.dashboard.navbardata;
export const getCreditsAvailable = (state) => state.dashboard.creditsAvailable;
export const getRestrictionModal = (state) => state.dashboard.restrictionModal;
