/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FilterListIcon from '@material-ui/icons/FilterList';
import { BackButton } from '../BackButton/BackButton';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
// import GlobalSearchBar from '../GlobalSearchBar/GlobalSearchBar';
import './PageHeader.scss';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Close } from '@mui/icons-material';

export const PageHeader = (props) => {
  const {
    title,
    search,
    placeholder,
    searchFilter,
    customBack,
    handleBack,
    transparent,
    transparentBlue,
    filter,
    triggerFilters,
    customIcon,
    handleCustomIcon,
    customIcon2,
    handleCustomIcon2,
    notFixed,
    shadow,
    noBack,
    cancelBack,
    handleCancel,
    iconColor,
    width,
    globalSearch,
    showDots,
    extendDotsArray = [],
    isWebView,
    goBackFunctionWebView,
    customColor,
  } = props;

  const [searchBar, triggerSearchBar] = useState(false);
  const [maxwidth, setMaxwidth] = useState(window.innerWidth);
  const [dummydata, setDummydata] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeSearchBar = () => {
    // eslint-disable-next-line no-empty
    if (searchFilter('')) {
      triggerSearchBar(false);
    } else {
      setDummydata(dummydata);
      triggerSearchBar(false);
    }
    // history.replace('/')
  };
  useEffect(() => {
    const handleResize = () => {
      setMaxwidth(window.innerWidth); // Update maxwidth on window resize
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      // this component is declared as Parent to whole PageHeader -- Raman
      className={
        notFixed
          ? 'notFixedPageHeader Parent_of_all_Pageheader p-3 d-flex align-items-center'
          : 'PageHeader Parent_of_all_Pageheader p-3 d-flex align-items-center'
      }
      style={{
        backgroundColor: transparent
          ? 'transparent'
          : transparentBlue
          ? 'rgba(241, 249, 255, 1)'
          : customColor != ''
          ? customColor
          : 'white',
        width,
        boxShadow: shadow ? '0px 2px 2px 0px rgba(0,0,0,0.29)' : 'transparent',
      }}
    >
      {!searchBar && (
        <>
          <div className='PageHeaderChild1'>
            {!noBack &&
              (customBack ? (
                <ArrowBackIcon
                  color={iconColor}
                  onClick={() => {
                    if (!isWebView) {
                      handleBack();
                    } else {
                      goBackFunctionWebView();
                    }
                  }}
                />
              ) : (
                <BackButton color={iconColor} />
              ))}
            {cancelBack && <Close onClick={handleCancel} />}
            {maxwidth <= 500 ? (
              <span className='ml-3 PageHeader__title'>
                {title.length > 28 ? `${title.slice(0, 28)}...` : title}
              </span>
            ) : (
              <span className='ml-3 PageHeader__title'>{title}</span>
            )}
            {/* {globalSearch ? <GlobalSearchBar /> : null} */}
          </div>
          {/* <div className='ml-auto Child2'> */}
          <div className='Child2'>
            {search && (
              <span
                role='button'
                tabIndex='-1'
                onKeyDown={() => triggerSearchBar(true)}
                onClick={() => triggerSearchBar(true)}
              >
                <SearchIcon className='mr-3' />
              </span>
            )}
            {filter && (
              <span role='button' tabIndex='-1' onKeyDown={triggerFilters} onClick={triggerFilters}>
                <FilterListIcon className='mr-3' />
              </span>
            )}
            {customIcon && (
              <span
                className='mr-3'
                onClick={() => handleCustomIcon()}
                onKeyDown={() => handleCustomIcon()}
                tabIndex='-1'
                role='button'
              >
                {customIcon}
              </span>
            )}
            {customIcon2 && (
              <span
                className='mr-3'
                onClick={() => handleCustomIcon2()}
                onKeyDown={() => handleCustomIcon2()}
                tabIndex='-1'
                role='button'
              >
                {customIcon2}
              </span>
            )}
            {showDots && (
              <IconButton
                id='basic-button'
                className='p-0'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {extendDotsArray.map((ele) => {
                return (
                  <MenuItem
                    key={ele.name}
                    className='PageHeader__text'
                    onClick={() => {
                      ele.function();
                      handleClose();
                    }}
                  >
                    {ele.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </>
      )}
      {searchBar && (
        <>
          <SearchIcon />
          <input
            autoFocus // eslint-disable-line
            placeholder={placeholder}
            className='PageHeader__input mx-2 p-2'
            onChange={(e) => searchFilter(e.target.value)}
          />
          <span
            role='button'
            tabIndex='-1'
            onKeyDown={() => closeSearchBar()}
            onClick={() => closeSearchBar()}
            className='ml-auto'
          >
            <ClearIcon className='mr-3' />
          </span>
        </>
      )}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  search: PropTypes.bool,
  placeholder: PropTypes.string,
  shadow: PropTypes.bool,
  searchFilter: PropTypes.func,
  triggerFilters: PropTypes.func,
  customBack: PropTypes.bool,
  handleBack: PropTypes.func,
  transparent: PropTypes.bool,
  transparentBlue: PropTypes.bool,
  filter: PropTypes.bool,
  notFixed: PropTypes.bool,
  noBack: PropTypes.bool,
  iconColor: PropTypes.string,
  customIcon: PropTypes.element,
  handleCustomIcon: PropTypes.func,
  customIcon2: PropTypes.element,
  handleCustomIcon2: PropTypes.func,
  width: PropTypes.string,
  globalSearch: PropTypes.bool,
  customColor: PropTypes.string,
  isWebView: PropTypes.bool,
  goBackFunctionWebView: PropTypes.func,
};

PageHeader.defaultProps = {
  title: '',
  width: '100%',
  search: false,
  iconColor: 'black',
  notFixed: false,
  shadow: false,
  noBack: false,
  placeholder: '',
  searchFilter: () => {},
  customBack: false,
  handleBack: () => {},
  triggerFilters: () => {},
  transparent: false,
  transparentBlue: false,
  filter: false,
  customIcon: null,
  handleCustomIcon: () => {},
  customIcon2: null,
  handleCustomIcon2: () => {},
  globalSearch: false,
  customColor: '',
  isWebView: false,
  goBackFunctionWebView: () => {},
};
