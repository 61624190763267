import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import io from 'socket.io-client';
// import loadable from '@loadable/component';
// import { Chart as ChartJS } from 'chart.js/auto';
import { getCurrentcolor } from '../redux/reducers/color.reducer';
import { getCurrentBranding } from '../redux/reducers/branding.reducer';
import { conversationsActions } from '../redux/actions/conversations.action';
import { setGlobalColors, changeFaviconAndDocumentTitle, isWebView, isIOS } from '../Utilities';
import { Loader } from '../Components/Common/Loader/Loading';
import './App.scss';
import { history, Routes } from '../Routing';
import { dashboardActions } from '../redux/actions/dashboard.action';
import { getCurrentLoadingStatus, getStatusOfSpinner } from '../redux/reducers/loading.reducer';
import withClearCache from './BustCache';
import { get, server } from '../Utilities/Remote';
import DevToolsBlocker from './Debugger';
import { getClientId } from '../redux/reducers/clientUserId.reducer';

import { getRestrictionModal } from '../redux/reducers/dashboard.reducer';
import { getToken } from '../redux/reducers/userProfile.reducer';
// import BottomNavigation from '../Components/Common/BottomNavigation/BottomNavigation';
// import Sidebar from '../Version2/Components/Sidebar/Sidebar';

// const io = loadable.lib(() => import('socket.io-client'));

function MainApp(props) {
  const {
    color,
    currentbranding,
    isLoading,
    clientId,
    isSpinner,
    token,
    setScrolledHeightOfDocumentToStore,
    setCreditsAvailableToStore,
  } = props;

  useEffect(() => {
    if (Object.keys(color.color) !== 0) {
      const { primary, light, lighter, superLight } = color.color;
      setGlobalColors(primary, light, lighter, superLight);
    }

    if (Object.keys(currentbranding.branding) !== 0) {
      const { client_icon: clientIcon, client_title: clientTitle } = currentbranding.branding;
      changeFaviconAndDocumentTitle(clientIcon, clientTitle);
    }
  }, [color, currentbranding, isSpinner]);

  useEffect(() => {
    get({ client_id: clientId }, '/getCreditsOfClient').then((res) => {
      if (res && res.success) {
        const credits = Number(res.result).toFixed(2);
        if (token) {
          if (credits < 1) {
            history.replace('/service-unavailable');
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'auto';
          }
        }
        setCreditsAvailableToStore(credits);
      }
    });
  }, [token]);

  return (
    <Container fluid className='p-0 m-0 rootContainer mx-auto'>
      <DevToolsBlocker>
        {isLoading && <Loader isSpinner={isSpinner} />}
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </DevToolsBlocker>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSocket: (socket) => {
      dispatch(conversationsActions.setSocket(socket));
    },
    setScrolledHeightOfDocumentToStore: (payload) => {
      dispatch(dashboardActions.setScrolledHeightOfDocumentToStore(payload));
    },
    setCreditsAvailableToStore: (payload) => {
      dispatch(dashboardActions.setCreditsAvailableToStore(payload));
    },
  };
};

const mapStateToProps = (state) => ({
  color: getCurrentcolor(state),
  currentbranding: getCurrentBranding(state),
  isLoading: getCurrentLoadingStatus(state),
  isSpinner: getStatusOfSpinner(state),
  clientId: getClientId(state),
  token: getToken(state),
});

const BustCache = withClearCache(connect(mapStateToProps, mapDispatchToProps)(MainApp));

function App() {
  return <BustCache />;
}

export default App;

MainApp.propTypes = {
  color: PropTypes.shape({
    color: PropTypes.shape({
      primary: PropTypes.string,
      light: PropTypes.string,
      lighter: PropTypes.string,
      superLight: PropTypes.string,
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentbranding: PropTypes.shape({
    branding: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  isSpinner: PropTypes.bool.isRequired,
  setSocket: PropTypes.func.isRequired,
  setScrolledHeightOfDocumentToStore: PropTypes.func.isRequired,
};
